import React from 'react'
import FormSection from './sections/FormSection'
import courses from '../router/coursesArray'

function CourseTwo() {
  return (
    <div className='courseTwo'>
      <div className='courseTwo-page'>
        <div className='bg'>
          <div className='content-div text-center'>
            <p className='main-heading mb-0'>Our Courses</p>
            <p className='main-description mb-0'>
              Home / Our Courses / C++ Language
            </p>
          </div>
        </div>
      </div>
      <div className='spacing'>
        {
          courses.courseTwo.map((x, i) => {
            return (
              <>
                <div className='text-center courses-div'>
                  <p className='main-description  mb-2'>
                    {x.firstTitle}
                  </p>
                  <p className='main-content text-white mb-3 grey'>
                    {x.firstContent}
                  </p>
                  <div className='d-flex justify-content-center mb-5'>
                    <button className=' button me-2 main-content '>{x.firstButton1}</button>
                    <button className='button ms-2 main-content'>{x.firstButton2}</button>
                  </div>
                  <p className='main-description  mb-2'>
                    {x.secondTitle}
                  </p>
                  <ul className='row row-cols-2 justify-content-center mb-5'>
                    {
                      x.secondcontent.map((y,j)=>{
                        return(
                          <li>{y.including}</li>
                        )
                      })
                    }
                     </ul>
                     <p className='main-description  mb-2'>
                    {x.thirdTitle}
                  </p>
                  <div className='d-flex justify-content-center mb-5'>
                    <button className=' button me-2 main-content '>{x.thirdButton1}</button>
                    <button className='button ms-2 main-content'>{x.thirdButton2}</button>
                  </div>
                  <p className='main-description  mb-2'>
                    Coding Fundamentals
                  </p>
                  <div className='row row-cols-2 bullets-sec'>
                    <div className='col pe-2'>
                      <div className='bullets'>
                        <p className='m-0 blue'>Introduction to C++ Programming</p>
                        <div className='d-flex'>
                            <p>1. </p>
                            <div>
                              <p className='ps-2'>Overview of C++ Language</p>
                              <p>- History and Evolution from C</p>
                              <p>- Applications and Importance</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                            <p>2. </p>
                            <div>
                              <p className='ps-2'>Setting up the C++ Development Environment</p>
                              <p>- Installing a Compiler</p>
                              <p>- Introduction to IDEs-  Integrated Development Environment</p>
                          </div>
                        </div>
                        </div>
                        <div className='bullets'>
                        <p className='m-0 blue'>Basics of C++ Programming</p>
                        <div className='d-flex'>
                            <p>3. </p>
                            <div>
                              <p className='ps-2'>Basic Syntax and Structure</p>
                              <p>- First C++ Program: Hello World</p>
                              <p>- Understanding C++ Program structure</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                          <p>4. </p>
                          <div>
                            <p className='ps-2'>Variables,Data Types, and Variables</p>
                            <p>- Fundamental Data Types</p>
                            <p>- Operators in C++ : Arithmetic, Logical, Assignment</p></div>
                        </div>
                        </div>
                        <div className='bullets'>
                        <p className='m-0 blue'>Control Structures in C++</p>
                        <div className='d-flex'>
                            <p>5. </p>
                            <div>
                              <p className='ps-2'>Conditional Statements</p>
                              <p>- if, else, and else if Statements</p>
                              <p>- Switch Case Statements</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                          <p>6. </p>
                          <div>
                            <p className='ps-2'>Loops and Iteration</p>
                            <p>- for,while and do-while Loops</p>
                            </div>
                        </div>
                        </div>
                        <div className='bullets'>
                        <p className='m-0 blue'>Functions and Scope</p>
                        <div className='d-flex'>
                            <p>7. </p>
                            <div>
                              <p className='ps-2'>Functions in C++</p>
                              <p>- Function Declaration and Definition</p>
                              <p>- Parameter Passing and Return Types</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                          <p>8. </p>
                          <div>
                            <p className='ps-2'>Namespaces and Scope</p>
                            <p>- Local and Global Scope</p>
                            <p>- Introduction to Namespaces</p></div>
                        </div>
                        </div>
                        </div>
                        <div className='col pe-2'>
                        <div className='bullets'>
                        <p className='m-0 blue'>Object-Oriented Programming in C++</p>
                        <div className='d-flex'>
                            <p>9. </p>
                            <div>
                              <p className='ps-2'>Classes and Objects</p>
                              <p>- Defining Classes and Creating Objects</p>
                              <p>- Constructors and Destructors</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                            <p>10. </p>
                            <div>
                              <p className='ps-2'>Encapsulation and Access Modifiers</p>
                              <p>- Public, Private, and Protected Members</p>
                              <p>- Member Functions and Properties</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                            <p>11. </p>
                            <div>
                              <p className='ps-2'>nheritance in C++</p>
                              <p>- Base and Derived Classes</p>
                              <p>- Types of Inheritance</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                            <p>12. </p>
                            <div>
                              <p className='ps-2'>Polymorphism</p>
                              <p>- Function Overloading and Overriding</p>
                              <p>- Virtual Functions</p>
                          </div>
                        </div>
                        </div>
                        <div className='bullets'>
                        <p className='m-0 blue'>Standard Template Library : STL</p>
                        <div className='d-flex'>
                            <p>13. </p>
                            <div>
                              <p className='ps-2'>String Handling in C++</p>
                              <p>- C++ String Class</p>
                              <p>- String Operations and Manipulations</p>
                          </div>
                        </div>
                        </div>
                        <div className='bullets'>
                        <p className='m-0 blue'>File Handling and Exception Handling</p>
                        <div className='d-flex'>
                            <p>14. </p>
                            <div>
                              <p className='ps-2'>File I/O in C++</p>
                              <p>- File Stream Classes</p>
                              <p>- Reading and Writing Files</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                            <p>15. </p>
                            <div>
                              <p className='ps-2'>Exception Handling</p>
                              <p>- Try, Catch, and Throw</p>
                              <p>- Custom Exception Classes</p>
                          </div>
                        </div>
                        </div>
                        <div className='bullets'>
                        <p className='m-0 blue'>Putting It All Together</p>
                        <div className='d-flex'>
                            <p>16. </p>
                            <div>
                              <p className='ps-2'>Sample Projects and Practical Applications</p>
                          </div>
                        </div>
                        </div>
                    </div>
                  </div>
                </div>
              </>
            )
          })
        }
      </div>
      <FormSection />
    </div>
  )
}

export default CourseTwo