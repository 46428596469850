import React from 'react';
import Slider from 'react-slick';
import service1 from '../../assests/media/images/service1.png'
import service2 from '../../assests/media/images/service2.png'
import service3 from '../../assests/media/images/service3.png'
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import { motion } from 'framer-motion';

function ServicesSection() {
  //animation
  const controls = useAnimation();
  const [ref, inView] = useInView();

  const variantsLeft = {
    hidden: { opacity: 0, x: '100%' ,delay:0.5},
    visible: { opacity: 1, x: '0%' }, 
  };
  
  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  //sliders
    const services = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              infinite: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 520,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
        ],
      }
      
  return (
    <motion.div className='our-services text-center spacing' ref={ref}>
    <p className='main-description mb-0'>
      Our Services
    </p>
    <p className='main-content mb-3 grey'>"Crafting Your Digital Presence Innovative Flutter specializes in developing sleek, user-centric apps and websites,
      intertwining advanced technology with innovative design to propel your brand in the digital landscape."
    </p>
    <motion.div className='pt-3 service-div'
    initial='hidden'
    animate={controls}
    variants={variantsLeft}
    transition={{ duration: 0.8 }}>
      <Slider {...services}>
        <div className='service-cards'>
          <div className='py-3 services-content'>
            <img src={service1}></img>
            <p>UI/UX Designing</p>
          </div>
        </div>
        <div className='service-cards'>
          <div className='py-3 services-content'>
            <img src={service2}></img>
            <p>Application developer</p>
          </div>
        </div>
        <div className='service-cards'>
          <div className='py-3 services-content'>
            <img src={service3}></img>
            <p>Android Developer</p>
          </div>
        </div>
      </Slider>
    </motion.div>
  </motion.div>
  )
}

export default ServicesSection