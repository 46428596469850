import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot,faEnvelope,faPhone} from '@fortawesome/free-solid-svg-icons'
import { faInstagram,faLinkedinIn,faBehance,faDribbble} from '@fortawesome/free-brands-svg-icons'

function Footer() {
  const handlePhoneClick = (phoneNumber) => {
    window.open(`tel:${phoneNumber}`, '_self');
  };
  const handleEmailClick = (emailAddress) => {
    window.location.href = `mailto:${emailAddress}`;
  };
  return (
    <>
    <div className='footter spacing'>
      <div className='pt-4'>
      <div className='row row-cols-q row-cols-md-2 row-cols-lg-4 pt-5 ps-2'>
        <div className='pt-3'>
          <p className='main-description mb-2'>
            Innovative Flutter
          </p>
          <p className='description m-0'>
            Innovative flutter is a software
            development company. We are providing a wide range of service such as app development, web development, and design, digital marketing service.
          </p>
        </div>
        <div className='ps-0 ps-lg-5 pt-3'>
          <p className='main-description mb-2'>
            Useful Link
          </p>
          <div className='description'>
            <ul>
            <li className='m-0'>
              About Us
            </li>
            <li className='m-0'>
              Portfolio
            </li>
            <li className='m-0'>
              Why Us
            </li>
            <li className='m-0'>
              Contact Us
            </li>
            </ul>
          </div>

        </div>
        <div className='px-3 pt-3'>
          <p className='main-description mb-2'>
            Services
          </p>
          <div className='description '>
            <p className='m-0'>
              App Development
            </p>
            <p className='m-0'>
              UI/UX Design
            </p>
          </div>
        </div>
        <div className='px-3 pt-3'>
          <p className='main-description mb-2'>
            Contact
          </p>
          <div className='description'>
              <p className='mb-1 d-flex'>
                <FontAwesomeIcon className='icons' icon={faLocationDot} />
                <p className='m-0 d-flex align-items-center'>
                  406, Elita Squre, VIP circle,
                  Uttran, Surat- 394105
                </p>
              </p>
              <p className='mb-1 d-flex align-items-center'>
                <FontAwesomeIcon className='icons' icon={faEnvelope} />
                <p className='m-0' onClick={() => handleEmailClick('info@innovativeflutter.com')}>
                  info@innovativeflutter.com
                </p>
              </p>
              <p className='mb=1 d-flex align-items-center'>
                <FontAwesomeIcon className='icons' icon={faPhone} />
                <p className='m-0' onClick={() => handlePhoneClick('+918000059470')}>
                  +918000059470
                </p>
              </p>
            </div>
        </div>
      </div>
    </div>
    </div>
    <div className='spacing copyright-div d-flex'>
      <p className='copyright mb-0'>Copyright @ 2023 All right reserved | Design made with 💙 by Innovative Flutter </p>
      <p className='mb-0 ms-auto row row-cols-4 icons-div'>
      <FontAwesomeIcon className='col icons shadow' icon={faInstagram} />
      <FontAwesomeIcon className='col icons shadow' icon={faLinkedinIn} />
      <FontAwesomeIcon className='col icons shadow' icon={faBehance} />
      <FontAwesomeIcon className='col icons shadow' icon={faDribbble} />
      </p>
    </div>
    </>
  )
}

export default Footer