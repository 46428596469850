import React from 'react';
import { motion } from 'framer-motion';
import aboutUs from '../../assests/media/images/aboutUs.png'
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import { useNavigate } from 'react-router-dom'

export default function AboutUsSection() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  const variantsLeft = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: '-50%', transition: { delay: 0.5 }  },
  };

  const variantsRight = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: '50%', transition: { delay: 0.5 }  },
  };

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);
  const navigate = useNavigate();
  const NavigateToPortfolio = () => {
    navigate('/portfolio')
  }

  return (
       <motion.div className='spacing row row-cols-1 row-cols-md-2 pb-2' ref={ref}>
      <motion.div
        className='d-flex flex-column justify-content-center pe-0 pe-md-3 order-2 order-md-1'
        initial='hidden'
        animate={controls}
        variants={variantsRight}
        transition={{ duration: 0.5 }}
      >
        <p className='main-description mb-0'>About Us</p>
        <h3 className='aboutUs-heading'>Innovative Flutter</h3>
        <p className='aboutUs-content grey'>Innovetive Flutter is a leading IT company specializing in
          innovative Mobile Applicatio  n Design(UI/UX Designing) &
          Mobile Application Devlopment, solutions and cutting-edge
          technology services. With a commitment to excellence, our
          team of experts collaborates with clients to transform their
          digital vision into reality, ensuring growth, efficiency, and
          sustainability." </p>
        <button className='rounded-pill bg-bblue btn-width text-white mb-4 mb-md-0' onClick={NavigateToPortfolio}>See Portfolio</button>
      </motion.div>
      <motion.div
        className='order-1 order-md-2 d-flex justify-content-center'
        initial='hidden'
        animate={controls}
        variants={variantsLeft}
        transition={{ duration: 0.5 }}
      >
        <img className='img-fluid' src={aboutUs} alt='About Us' />
      </motion.div>
    </motion.div>
  )
}
