// CounterContext.js
import React, { createContext, useState } from 'react';

const Context = createContext();

const ContextProvider = ({ children }) => {
    const [isCourse, setisCourse] = useState(false)

  return (
    <Context.Provider value={{ isCourse, setisCourse }}>
      {children}
    </Context.Provider>
  );
};

export { ContextProvider ,Context};
