import choose1 from '../assests/media/images/choose1.png';
import choose2 from '../assests/media/images/choose2.png';
import choose3 from '../assests/media/images/choose3.png';
import choose4 from '../assests/media/images/choose4.png';
import choose5 from '../assests/media/images/choose5.png';
import choose6 from '../assests/media/images/choose6.png';
import app1 from '../assests/media/images/app1.png';
import app2 from '../assests/media/images/app2.png';
import app3 from '../assests/media/images/app3.png';
import app4 from '../assests/media/images/app4.png';
import app5 from '../assests/media/images/app5.png';
import app6 from '../assests/media/images/app6.png';
import app7 from '../assests/media/images/app7.png';
import app8 from '../assests/media/images/app8.png';
import app9 from '../assests/media/images/app9.png';
import app10 from '../assests/media/images/app10.png';
import app11 from '../assests/media/images/app11.png';
import app12 from '../assests/media/images/app12.png';
import app13 from '../assests/media/images/app13.png';
import app14 from '../assests/media/images/app14.png';
import app15 from '../assests/media/images/app15.png';
import app16 from '../assests/media/images/app16.png';



const statesticArray = [
    {
        numbers: '9',
        title: "Years Experiance",
        description: "With over 9 years  a skilled expert in Application development, and driving industry-leading tech innovations."
    },
    {
        numbers: '100',
        title: 'Happy Clients',
        description: 'With 100+ satisfied clients, we have consistently provided top-tier mobile app development and design, earning a reputation for excellence in client satisfaction'
    },
    {
        numbers: '100',
        title: 'Successful Projects',
        description: 'We have delivered 100+ mobile apps, blending premier design with seamless development, defining industry excellence'
    }
]

const whyChooseUsArray = [
    {
        image: choose1,
        title: 'Result Oriented Approach',
        description: 'We focus on achieving measurable result for our clients and use analytics and data to constantly improve our campaigns.'
    },
    {
        image: choose2,
        title: 'Customer Centric',
        description: 'We prioritize our clients needs and strive to deliver exceptional customer service at every touchpoint.'
    },
    {
        image: choose3,
        title: 'Customized Approch',
        description: 'We take a customized approach to each clients needs and create bespoke strategies that are tailored to their business goals.'
    },
    {
        image: choose4,
        title: 'Expertise',
        description: 'Our team consists of highly experinenced professionals who have a deep understanding of digital marketing strategies and techniques.'
    },
    {
        image: choose5,
        title: '24/7 Call Support',
        description: 'We offer expertise, customized strategies, results driven, transparency and 24/7 call support, while staying cost effective, creative and customer centric.'
    },
    {
        image: choose6,
        title: 'Transparency',
        description: 'We believe in transparency and communicate regularly with our clients to ensure they are always aware of our progress and the status of their compaigns.'
    },
]

const portfolio = [
    {
        title:'Ayurvedic E-Commerce App',
        image:app1,
        link:'https://www.behance.net/gallery/176908545/Ayaurvedic-E-commerce-App',
    },
    {
        title:'Poto Editing Application',
        image:app2,
        link:'https://www.behance.net/gallery/176909011/Photo-Editing-app',
    },
    {
        title:'GST Application',
        image:app3,
        link:'https://www.behance.net/gallery/176910007/GST-Calculator',
    },
    {
        title:'VPN Application',
        image:app4,
        link:'https://www.behance.net/gallery/176912531/VPN-App',
    },
    {
        title:'EMI Application',
        image:app5,
        link:'https://www.behance.net/gallery/177175287/EMI-Calculator',
    },
    {
        title:'Coffee Shop Application',
        image:app6,
        link:'https://www.behance.net/gallery/178223973/Coffee-Shop-App',
    },
    {
        title:'Furniture Application',
        image:app7,
        link:'https://www.behance.net/gallery/178998847/Furniture-E-Commerce-App',
    },
    {
        title:'Gallery Application',
        image:app8,
        link:'https://www.behance.net/gallery/179171929/Gallery-App',
    },
    {
        title:'Photo Editor Application',
        image:app9,
        link:'https://www.behance.net/gallery/179337303/Photo-Editing-App',
    },
    {
        title:'VPN Application',
        image:app10,
        link:'https://www.behance.net/gallery/179339217/VPN-app',
    },
    {
        title:'Video Downloader App',
        image:app11,
        link:'https://www.behance.net/gallery/179528913/Video-Downloder-App',
    },
    {
        title:'Photo Editing App',
        image:app12,
        link:'https://www.behance.net/gallery/179693837/Photo-Editor-App',
    },
    {
        title:'GST Application',
        image:app13,
        link:'https://www.behance.net/gallery/179697101/GST-Calculator',
    },
    {
        title:'VPN Application',
        image:app14,
        link:'https://www.behance.net/gallery/179713863/VPN-APP-(gradient-theme)',
    },
    {
        title:'GST Application',
        image:app15,
        link:'https://www.behance.net/gallery/179795669/GST-Calculator',
    },
    {
        title:'Aadhar Pe Loan Application',
        image:app16,
        link:'https://www.behance.net/gallery/180590539/Adhar-pe-loan',
    },
]

export default { statesticArray ,whyChooseUsArray,portfolio};