import React from 'react'
import ServicesSection from './sections/servicesSection'
import FormSection from './sections/FormSection'

function Services() {
  return (
    <>
    <div className='service-page'>
        <div className='bg'>
          <div className='content-div text-center'>
            <p className='main-heading mb-0'>Services</p>
            <p className='main-description mb-0'>
            Excellence Delivered, Every Service, Every Time            </p>
          </div>
        </div>
      </div>
      <ServicesSection/>
      <FormSection/>
      </>
  )
}

export default Services