import { BrowserRouter,Routes,Route } from 'react-router-dom';
import pageRoutes from './router/routes';
import DefaultLayout from './defaultLayout/DefaultLayout';
import './App.scss'
import Navbar from './layout/Component/Navbar';
import { ContextProvider } from './helper/ContextProvider';
function App() {
  return (
    <>
    <ContextProvider>
    <BrowserRouter>
        <Routes>
            {
              pageRoutes.map((pages, i) => {
                return (
                  <Route exact path='/' element={<DefaultLayout />}>
                    <Route path={pages.path} exact key={i} element={pages.component} />
                  </Route>
                )
              })
            }
        </Routes>
      </BrowserRouter>
    </ContextProvider>
    </>
  );
}

export default App;
