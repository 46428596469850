const nav = [
    {
        id: '1',
        name: "Home",
        icon: <i className="fa fa-tachometer"></i>,
        path: '/'
    },
    {
        id: '2',
        name: "About Us",
        path: '/aboutus'
    },
    {
        id: '3',
        name: "Services",
        path: '/services'
    },
    {
        id: '4',
        name: "Courses",
        path: ''
    },
    {
        id: '5',
        name: "Portfolio",
        path: '/portfolio'
    },
    {
        id: '6',
        name: "Why Us",
        path: '/whyus',
    },
    {
        id: '7',
        name: "Contact Us",
        path: '/contactus'
    },
]

export default nav;