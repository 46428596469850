import React, { useContext, useEffect ,useRef, useState} from 'react'
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom'
import AboutUsSection from './sections/aboutUsSection';
import homePageArray from '../router/homePageArray';
import tech1 from '../assests/media/images/tech1.jpg';
import tech2 from '../assests/media/images/tech2.jpg'
import tech3 from '../assests/media/images/tech3.jpg'
import tech4 from '../assests/media/images/tech4.jpg'
import progress from '../assests/media/images/progress.png'
import progress1 from '../assests/media/images/progress1.png'
import progress2 from '../assests/media/images/progress2.png'
import progress3 from '../assests/media/images/progress3.png'
import progress4 from '../assests/media/images/progress4.png'
import progress5 from '../assests/media/images/progress5.png'
import progress6 from '../assests/media/images/progress6.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import FormSection from './sections/FormSection';
import ServicesSection from './sections/servicesSection';
import ChooseUsSection from './sections/ChooseUsSection';
import CourseSection from './sections/CourseSection';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { faSlideshare } from '@fortawesome/free-brands-svg-icons';
import { Context } from '../helper/ContextProvider';

function Home() {
  const {isCourse,setisCourse} =useContext(Context)
  const formSectionRef = useRef(null);
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const statSectionRef = useRef(null);
  const controls = useAnimation();
  const technologiesDivRef = useRef(null);
  const [isSectionVisible, setIsSectionVisible] = useState(false);

  //service section
  const services = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  }

  //scroll to form section
  const scrollToFormSection = () => {
    if (formSectionRef.current) {
      formSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  //navigate to portfolio
  const goToPortfolioPage=()=>{
    navigate('/portfolio')
  }

  useEffect(() => {
   if(isCourse){
    const courseSection = document.getElementById('course-section');
      if (courseSection) {
        courseSection.scrollIntoView({ behavior: 'smooth' });
        setisCourse(faSlideshare)
      }

   }
  }, [isCourse])
  

  //statestics-div animation
  useEffect(() => {
    const section = statSectionRef.current;
    const onScroll = () => {
      if (section) {
        const offsetTop = section.offsetTop;
        const offsetHeight = section.offsetHeight;
        const scrollPosition = window.scrollY;
        if (scrollPosition > offsetTop - window.innerHeight + offsetHeight * 0.5) {
          setIsVisible(true);
        } else {
          setIsVisible(false); // Reset visibility if not in view
        }
      }
    };
    window.addEventListener('scroll', onScroll);
    onScroll();
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    let timeoutId;
    if (isVisible) {
      timeoutId = setTimeout(() => {
        controls.start({
          opacity: 1,
          y: 0,
          transition: { duration: 0.5 },
        });
      }, 200); // Delay of 0.5 seconds (500 milliseconds) before starting animation
    } else {
      controls.start({ opacity: 0, y: 80 }); // Reset animation when not visible
    }
    return () => clearTimeout(timeoutId); // Clear timeout on cleanup
  }, [isVisible, controls]);

  //progress-div animation
  const progressControls = useAnimation();
  const [progressImgRef, progressinView] = useInView();
  const containerVariants = {
    hidden: { opacity: 0, scale: 0,rotate:360 },
    visible: {
      opacity: 1,
      scale: 1,
      rotate:0,
      transition:{
        delay:0.2,
        duration:1
      }
    },
  };
  React.useEffect(() => {
    if (progressinView) {
      progressControls.start('visible');
    } else {
      progressControls.start('hidden');
    }
  }, [progressControls, progressinView]);

  //technologies-div animation
  const controlsImages = useAnimation();
  const [techImgRef, techinView] = useInView();
  React.useEffect(() => {
    if (techinView) {
      controlsImages.start('visible');
    } else {
      controlsImages.start('hidden');
    }
  }, [controlsImages, techinView]);

  return (
    <>
      <div className='home'>
        <div className='bg-home px-3'>
          <div className='content-div'>
            <p className='main-heading mb-0'>Innovative Flutter</p>
            <p className='main-description mb-0'>Your Team for Cutting-Edge Development and Design</p>
            <p className='pb-5 main-content mb-0'>For 10 years, we simplify the digital transformation of businesses
              with next-generation custom software solutions. And are trusted by 100+ customers for empowering them with agility & automation to lead the evolving world today and tomorrow.
            </p>
            <div>
              <button className='py-1 bg-white rounded-pill work-btn' onClick={scrollToFormSection}>Let’ Work Together</button>
              <button className='py-1 mt-2 mt-md-2 ms-0 ms-md-2 rounded-pill portfolio-btn' onClick={goToPortfolioPage}>See Portfolio</button>
            </div>
          </div>
        </div>
      </div>
      <ServicesSection/>
      <div id='course-section'>
      <CourseSection/>
      </div>
      <AboutUsSection />
      <div className='statestic-div' ref={statSectionRef}>
        <div className='inner-container '>
          <Slider {...services}>
          {
            homePageArray.statesticArray.map((x, i) => {
              return (
                <motion.div
                className='demo'
                initial={{ opacity: 0, y: 50 }}
                animate={controls}
                style={{ opacity: 0, y: 50 }}
              > 
                  <div className='outer-container1 text-center'>
                    <p className='mb-4 mb-md-5 title'>{x.numbers}</p>
                    <p className='blue mb-2 s-title'>{x.title}</p>
                        <p className='mb-0 text-dark description'>{x.description}</p>
                  </div>
                </motion.div>
              )
            })
          }
          </Slider>
        </div>
      </div>
      <ChooseUsSection/>
      <div className='technologies-div'>
        <motion.div className='inner-container text-center'  ref={techImgRef}>
          <p className='main-description pb-4'>Technologies we work with</p>
          <div className='row row-cols-2 row-cols-md-4 gapping align-items-center justify-content-center '>
          <motion.img
        animate={controlsImages}
        variants={containerVariants}
        initial={{ opacity: 0, scale: 0.5 }}
        src={tech1}
        className='col img-fluid imgs'
      ></motion.img>
      <motion.img
        animate={controlsImages}
        src={tech2}
        variants={containerVariants}   
        initial={{ opacity: 0, scale: 0.5 }}     
        className='col img-fluid imgs'
      ></motion.img>
            <motion.img
        animate={controlsImages}
        src={tech3}
        variants={containerVariants}
        initial={{ opacity: 0, scale: 0.5 }}
        className='col img-fluid imgs'
      ></motion.img>
      <motion.img
        animate={controlsImages}
        src={tech4}
        variants={containerVariants}
        initial={{ opacity: 0, scale: 0.5 }}
        className='col img-fluid imgs'
      ></motion.img>
          </div>
        </motion.div>
      </div>
      <div className='progress-div spacing text-center'>
        <p className='main-description mb-0'>
          Our Work Process
        </p>
        <p className='main-content mb-0 grey'>
          The process steps on creating our awesome and beautiful projects.
        </p>
        <div className='p-3 progress-img-wrapper' ref={progressImgRef}>
          <motion.img 
           animate={progressControls}
           variants={containerVariants}
           initial={{ opacity: 0, scale: 0.5 }}
           src={progress}
           className='img-fluid progress-img'>
          </motion.img>
        </div>
        <div className='position-relative'>
          <div className='long-arrow'></div>
          <div className='row row-cols-3 row-cols-md-6 px-0 px-lg-4 pt-3 p-relative'>
            <div className='col inner-progress-div'>
              <div className='bg'>
                <div className='numbers'>1</div>
                <img className='img-fluid' src={progress1}></img>
              </div>
              <p className='py-3 m-0'>Meet</p>
            </div>
            <div className='col inner-progress-div'>
              <div className='bg arrow'>
                <div className='numbers'>2</div>
                <FontAwesomeIcon className='grey' icon={faArrowRight} />
                <img className='img-fluid ' src={progress2}></img>
              </div>
              <p className='py-3 m-0'>Plan</p>
            </div>
            <div className='col inner-progress-div'>
              <div className='bg'>
                <div className='numbers'>3</div>
                <FontAwesomeIcon className='grey' icon={faArrowRight} />
                <img className='img-fluid' src={progress3}></img>
              </div>
              <p className='py-3 m-0'>Design</p>
            </div>
            <div className='col inner-progress-div'>
              <div className='bg'>
                <div className='numbers'>4</div>
                <FontAwesomeIcon className='grey d-none d-md-block' icon={faArrowRight} />
                <FontAwesomeIcon className='grey d-block d-md-none' icon={faArrowLeft} />
                <img className='img-fluid' src={progress4}></img>
              </div>
              <p className='py-3 m-0'>Develop</p>
            </div>
            <div className='col inner-progress-div'>
              <div className='bg'>
                <div className='numbers'>5</div>
                <FontAwesomeIcon className='grey d-none d-md-block' icon={faArrowRight} />
                <FontAwesomeIcon className='grey d-block d-md-none' icon={faArrowLeft} />
                <img className='img-fluid' src={progress5}></img>
              </div>
              <p className='py-3 m-0'>Testing</p>
            </div>
            <div className='col inner-progress-div'>
              <div className='bg'>
                <div className='numbers'>6</div>
                <FontAwesomeIcon className='grey d-none d-md-block' icon={faArrowRight} />
                <img className='img-fluid' src={progress6}></img>
              </div>
              <p className='py-3 m-0'>Launch</p>
            </div>
          </div>
        </div>
      </div>
      <div ref={formSectionRef} id='form-section'>
      <FormSection />
      </div>
    </>

  )
}

export default Home



