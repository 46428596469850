import React from 'react'
import FormSection from './sections/FormSection'
import courses from '../router/coursesArray'

function CourseOne() {
  return (
    <div className='courseOne'>
      <div className='courseOne-page'>
        <div className='bg'>
          <div className='content-div text-center'>
            <p className='main-heading mb-0'>Our Courses</p>
            <p className='main-description mb-0'>
              Home / Our Courses / C Language
            </p>
          </div>
        </div>
      </div>
      <div className='spacing'>
        {
          courses.courseOne.map((x, i) => {
            return (
              <>
                <div className='text-center courses-div'>
                  <p className='main-description  mb-2'>
                    {x.firstTitle}
                  </p>
                  <p className='main-content text-white mb-3 grey'>
                    {x.firstContent}
                  </p>
                  <div className='d-flex justify-content-center mb-5'>
                    <button className=' button me-2 main-content '>{x.firstButton1}</button>
                    <button className='button ms-2 main-content'>{x.firstButton2}</button>
                  </div>
                  <p className='main-description  mb-2'>
                    {x.secondTitle}
                  </p>
                  <ul className='row row-cols-2 justify-content-center mb-5'>
                    {
                      x.secondcontent.map((y,j)=>{
                        return(
                          <li>{y.including}</li>
                        )
                      })
                    }
                     </ul>
                     <p className='main-description  mb-2'>
                    {x.thirdTitle}
                  </p>
                  <div className='d-flex justify-content-center mb-5'>
                    <button className=' button me-2 main-content '>{x.thirdButton1}</button>
                    <button className='button ms-2 main-content'>{x.thirdButton2}</button>
                  </div>
                  <p className='main-description  mb-2'>
                    Coding Fundamentals
                  </p>
                  <div className='row row-cols-2 bullets-sec'>
                    <div className='col pe-2'>
                      <div className='bullets'>
                        <p className='m-0 blue'>Introduction to C Programming</p>
                        <div className='d-flex'>
                            <p>1. </p>
                            <div>
                              <p className='ps-2'>Overview of C Language</p>
                              <p>- History and Importance</p>
                              <p>- Applications of C</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                            <p>2. </p>
                            <div>
                              <p className='ps-2'>Setting up the Development Environment</p>
                              <p>- Installing a Compiler</p>
                              <p>- Introduction to IDEs-  Integrated Development Environment</p>
                          </div>
                        </div>
                        </div>
                        <div className='bullets'>
                        <p className='m-0 blue'>Basics of C Programming</p>
                        <div className='d-flex'>
                            <p>3. </p>
                            <div>
                              <p className='ps-2'>Basic Syntax and Structure</p>
                              <p>- Hello World Program</p>
                              <p>- Understanding C Program structure</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                          <p>4. </p>
                          <div>
                            <p className='ps-2'>Data Types and Variables</p>
                            <p>- Primitive Data Types</p>
                            <p>- Declaring and Initializing Variables</p></div>
                        </div>
                        <div className='d-flex'>
                          <p>5. </p>
                          <div>
                            <p className='ps-2'>Operators and Expressions</p>
                            <p>- Arithmetic Operators</p>
                            <p>- Logical Operators</p>
                            <p>- Assignment Operators</p></div>
                        </div>
                        </div>
                        <div className='bullets'>
                        <p className='m-0 blue'>Control Structures</p>
                        <div className='d-flex'>
                            <p>6. </p>
                            <div>
                              <p className='ps-2'>Conditional Statements</p>
                              <p>- if, else, and else if Statements</p>
                              <p>- Switch Case Statements</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                          <p>7. </p>
                          <div>
                            <p className='ps-2'>Loops</p>
                            <p>- for Loop</p>
                            <p>- while and do-while Loops</p></div>
                        </div>
                        </div>
                        </div>
                        <div className='col pe-2'>
                        <div className='bullets'>
                        <p className='m-0 blue'>Functions and Modular Programming</p>
                        <div className='d-flex'>
                            <p>8. </p>
                            <div>
                              <p className='ps-2'>Functions in C</p>
                              <p>- Defining and Calling Functions</p>
                              <p>- Return Types and Parameters</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                          <p>9. </p>
                          <div>
                            <p className='ps-2'>Scope and Lifetime of Variables</p>
                            <p>- Local and Global Variables</p>
                            <p>- Static Variables</p></div>
                        </div>
                        </div>
                        <div className='bullets'>
                        <p className='m-0 blue'>Advanced Topics</p>
                        <div className='d-flex'>
                            <p>10. </p>
                            <div>
                              <p className='ps-2'>Arrays and Strings</p>
                              <p>- Declaring and Initializing Arrays</p>
                              <p>- Basic String Operations</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                          <p>11. </p>
                          <div>
                            <p className='ps-2'> Pointers</p>
                            <p>- Understanding Pointers</p>
                            <p>- Pointer Arithmetic</p></div>
                        </div>
                        <div className='d-flex'>
                            <p>12. </p>
                            <div>
                              <p className='ps-2'>Dynamic Memory Allocation</p>
                              <p>- malloc, calloc, and free</p>
                          </div>
                        </div>
                        </div>
                        <div className='bullets'>
                        <p className='m-0 blue'>Structured Data Types</p>
                        <div className='d-flex'>
                            <p>13. </p>
                            <div>
                              <p className='ps-2'>Structures and Unions</p>
                              <p>- Defining and Using Structures</p>
                              <p>- Understanding Unions</p>
                          </div>
                        </div>
                        </div>
                        <div className='bullets'>
                        <p className='m-0 blue'>File Handling</p>
                        <div className='d-flex'>
                            <p>14. </p>
                            <div>
                              <p className='ps-2'>File I/O in C</p>
                              <p>- Reading and Writing Files</p>
                              <p>- File Operations Function</p>
                          </div>
                        </div>
                        </div>
                        <div className='bullets'>
                        <p className='m-0 blue'>Conclusion</p>
                        <div className='d-flex'>
                            <p>15. </p>
                            <div>
                              <p className='ps-2'>Course Recap and Next Steps</p>
                              <p>- Review of Key Concepts</p>
                              <p>- Resources for Further Learning</p>
                          </div>
                        </div>
                        </div>
                    </div>
                  </div>
                </div>
              </>
            )
          })
        }
      </div>
      <FormSection />
    </div>
  )
}

export default CourseOne