import React from 'react';
import Slider from 'react-slick';
import course1 from '../../assests/media/images/courses1.png'
import course2 from '../../assests/media/images/courses2.png'
import course3 from '../../assests/media/images/courses3.png'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';

function CourseSection() {
    const navigate = useNavigate();
    const navigateToCourses = (link) => {
        navigate(link)
    }
    
    //animation
    const controls = useAnimation();
    const [ref, inView] = useInView();
    const variantsRight = {
        hidden: { opacity: 0, x: '-100%' },
        visible: { opacity: 1, x: '0%' }, 
    };
    React.useEffect(() => {
        if (inView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [controls, inView]);

    //slider
    const courses = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        gap: '20px',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 520,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    }

    return (
        <motion.div className='our-courses text-center spacing' ref={ref}>
            <p className='main-description mb-0'>
                Our courses
            </p>
            <p className='main-content mb-3 grey'>Our Flutter development courses cover from basic widgets to advanced UI and backend integration, suited for both beginners
                and seasoned developers to master cross-platform mobile app development.
            </p>
            <motion.div className='pt-3 course-div'
             initial='hidden'
             animate={controls}
             variants={variantsRight}
             transition={{ duration: 0.8 }}
             >
                <Slider {...courses}>
                    <div className='course-cards px-2'>
                        <div className='courses-content'>
                            <img src={course1} className='img-fluid w-100'></img>
                            <div className='inner-div p-2 px-3 py-3'>
                                <p className='main-description text-dark '>C language</p>
                                <p className='content grey'>A C language course provides a comprehensive introduction to programming in C, covering fundamental concepts, syntax, and hands-on coding exercises to equip learners with the skills to develop efficient and portable software applications.
                                </p>
                                <a className='course-btn rounded-pill ' href='/courses/c-language'>View More</a>
                            </div>
                        </div>
                    </div>
                    <div className='course-cards px-2'>
                        <div className='courses-content'>
                            <img src={course2} className='img-fluid w-100'></img>
                            <div className='inner-div p-2 px-3 py-3'>
                                <p className='main-description text-dark'>C++ language</p>
                                <p className='content grey px-3'>A C++ language course equips learners with expertise in object-oriented programming, covering syntax, advanced features, and practical application development for building versatile and efficient software solutions.
                                </p>
                                <a className='course-btn rounded-pill ' href='/courses/c++-language'>View More</a>
                            </div>
                        </div>
                    </div>
                    <div className='course-cards px-2'>
                        <div className='courses-content'>
                            <img src={course3} className='img-fluid w-100'></img>
                            <div className='inner-div p-2 px-3 py-3'>
                                <p className='main-description text-dark mb-2'>Flutter & Dart</p>
                                <p className='content grey px-1'>A Flutter & Dart language course provides hands-on training in mobile app development, covering the Flutter framework and Dart programming language, enabling participants to create cross-platform applications with a single codebase.
                                </p>
                                <a className='course-btn rounded-pill ' href='/courses/flutter-dart'>View More</a>
                            </div>
                        </div>
                    </div>
                </Slider>
            </motion.div>
        </motion.div>
    )
}

export default CourseSection