import React, { useEffect, useRef } from 'react'
import homePageArray from '../router/homePageArray';

function Portfolio() {
  const portfolioSectionRef = useRef(null);

  useEffect(() => {
    if (portfolioSectionRef.current) {
      portfolioSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const navigateToApp = (link) =>{
    if (link) {
      window.open(link, '_blank');
    }
  }
  return (
    <>
      <div className='portfolio-page'>
        <div className='bg'>
          <div className='content-div text-center'>
            <p className='main-heading mb-0'>Portfolio</p>
            <p className='main-description mb-0'>
              Where Creativity Meets Results: Our Work Speaks
            </p>
          </div>
        </div>
      </div>
      <div className='our-projects text-center spacing' ref={portfolioSectionRef} id="portfolio">
        <p className='main-description mb-0'>
          Our Projects
        </p>
        <p className='main-content mb-3 grey'>Showcasing our dedication to innovation and excellence, our portfolio is a testament to our commitment to delivering results. Dive in and see visions transformed into reality.
        </p>
        <div className='projects-div spacing row row-cols-2 px-2 px-md-3 px-lg-5 w-100 w-xxl-50' >
            {
              homePageArray.portfolio.map((x,i)=>{
                return(
                  <div className='col '>
                      <div className='bg-col'>
                      <div className='project' style={{backgroundImage: `url(${x.image})`}} onClick={()=>navigateToApp(x.link)}>
                      <p className='project-title'>{x.title}</p>
                      {/* <img src={x.image} alt={x.title} className='img-fluid'></img> */}
                     <div className='project-btn-div'>
                     <a className='rounded-pill project-btn'>
                        click here..
                      </a>
                      </div>
                        </div>
                        </div>
                    </div>
                )
              })
            }
        </div>
      </div>
    </>
  )
}

export default Portfolio