import React from 'react'
import AboutUsSection from './sections/aboutUsSection'
import FormSection from './sections/FormSection'

function AboutUs() {
  return (
    <>
    <div className='aboutUs-page'>
        <div className='bg'>
          <div className='content-div text-center'>
            <p className='main-heading mb-0'>About Us</p>
            <p className='main-description mb-0'>
            Discover Our Story, Define Your Success
            </p>
          </div>
        </div>
      </div>
      <AboutUsSection/>
      <FormSection/>
      </>
  )
}

export default AboutUs