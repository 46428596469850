const courseOne = [
   { firstTitle:'About This Course ',
   firstContent  :'The C language is a foundational, high-level programming language known for its efficiency and close-to-hardware operations. It is widely used in system software, embedded systems, and applications requiring high performance. C emphasizes portability and procedural programming, making it crucial for understanding modern computin',
   firstButton1:'Course Duration 6 Months',
   firstButton2:'Daily Time 2 Hours',
   secondTitle:'Included in this Course',
    secondcontent : [
        {including:'Training Courses'},
        {including:'College Project Assistance'},
        {including:'Certification Programs'},
        {including:'Online Learning Platforms'},
        {including:'Workshops and Seminars'},
        {including:'Research and Development'},
        {including:'Corporate Training'},
        {including:'Internship Programs'},
    ],
    thirdTitle:'Courses Modules',
    thirdButton1:'Coding Fundamentals',
    thirdButton2:'Fundamentals Flutter',
}
]

const courseTwo = [
    { firstTitle:'About This Course ',
    firstContent  :'C++ is an object-oriented programming language, an extension of the C language, known for its high performance and efficiency. It supports both procedural and object-oriented programming paradigms, allowing for flexible and powerful software design. Widely used in software development, game development, and system programming, C++ offers features like classes, inheritance, and polymorphism, enabling complex and robust applications.',
    firstButton1:'Course Duration 6 Months',
    firstButton2:'Daily Time 2 Hours',
    secondTitle:'Included in this Course',
     secondcontent : [
         {including:'Training Courses'},
         {including:'College Project Assistance'},
         {including:'Certification Programs'},
         {including:'Online Learning Platforms'},
         {including:'Workshops and Seminars'},
         {including:'Research and Development'},
         {including:'Corporate Training'},
         {including:'Internship Programs'},
     ],
     thirdTitle:'Courses Modules',
     thirdButton1:'Coding Fundamentals',
     thirdButton2:'Fundamentals Flutter',
 }
 ]

 const courseThree = [
    { firstTitle:'About This Course ',
    firstContent  :'Flutter is an open-source UI software development kit created by Google, used for building natively compiled applications for mobile, web, and desktop from a single codebase. Dart, the programming language used by Flutter, is optimized for UI, offering smooth animations and transitions that render consistently across platforms. Together, Flutter and Dart streamline the development process, enabling fast and efficient creation of visually appealing and highly functional cross-platform applications.',
    firstButton1:'Course Duration 6 Months',
    firstButton2:'Daily Time 2 Hours',
    secondTitle:'Included in this Course',
     secondcontent : [
         {including:'Training Courses'},
         {including:'College Project Assistance'},
         {including:'Certification Programs'},
         {including:'Online Learning Platforms'},
         {including:'Workshops and Seminars'},
         {including:'Research and Development'},
         {including:'Corporate Training'},
         {including:'Internship Programs'},
     ],
     thirdTitle:'Courses Modules',
     thirdButton1:'Coding Fundamentals',
     thirdButton2:'Fundamentals Flutter',
 }
 ]

export default {courseOne,courseTwo,courseThree};