import React, { useEffect } from 'react';
import homePageArray from '../../router/homePageArray';
import Slider from 'react-slick';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { transform } from 'typescript';

function ChooseUsSection() {
  const choosesUs = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  }

  const controls = useAnimation();
  const [ref, inView] = useInView();
  const containerVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition:{
        delay:0.2,
        duration:1
      }
    },
  };
  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <div className='spacing text-center whyChooseUs'>
      <p className='main-description mb-0'>
        Why Choose Us
      </p>
      <p className='main-content mb-0 grey'>
        We believe in building strong, long-term relationship with our clients by providing ongoing support and guidance. Choose us
        for a partner that is invested in your success and committed to delivering results that exceed your expectations.
      </p>
      <div className='pt-3 chooseUs-div d-block d-lg-none'>
        <Slider {...choosesUs}>
          {
            homePageArray.whyChooseUsArray.map((x, i) => {
              return (
                <>
                  <div className='outer-container' key={i}>
                    <div className=' rounded-4 container'>
                      <div className='image-container d-flex justify-content-center align-items-center'>
                        <div className='inner-circle d-flex justify-content-center align-items-center'>
                          <img className='img-fluid' src={x.image}></img>
                        </div>
                      </div>
                      <div className='bg-white inner-div p-3'>
                        <p className='text-dark mb-0 title pt-4 pb-2 choose-title'>{x.title}</p>
                        <p className='grey description'>{x.description}</p>
                      </div>
                    </div>
                  </div>
                </>
              )
            })
          }
        </Slider>
      </div>
      <div className='pt-3 chooseUs-div d-none d-lg-flex row row-cols-lg-3' ref={ref} >
        {
          homePageArray.whyChooseUsArray.map((x, i) => {
            return (
              <>
                <motion.div
                  className='outer-container'
                  key={i}
                  variants={containerVariants}
                  initial='hidden'
                  animate={controls}
                >      
                  <div className=' rounded-4 container'>
                    <div className='image-container d-flex justify-content-center align-items-center'>
                      <div className='inner-circle d-flex justify-content-center align-items-center'>
                        <img className='img-fluid' src={x.image}></img>
                      </div>
                    </div>
                    <div className='bg-white inner-div p-3'>
                      <p className='text-dark mb-0 title pt-4 pb-2 choose-title'>{x.title}</p>
                      <p className='grey description'>{x.description}</p>
                    </div>
                  </div>
                </motion.div>
              </>
            )
          })
        }
      </div>
    </div>
  )
}

export default ChooseUsSection