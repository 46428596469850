import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot,faEnvelope,faPhone} from '@fortawesome/free-solid-svg-icons'
import { useForm } from 'react-hook-form';
import  axios  from 'axios';

function ContactUs() {
  const { register, handleSubmit, formState: { errors },reset } = useForm();
  const sendEmail = (data) => {
    const headers = {
      'Content-Type': 'application/json'
    }
    console.log(headers)
    return axios.post('https://innovativeflutter.com/ups/email-api.php',data,{headers})
        .then(res => res.data).catch(res => res.data)
}
  const onSubmit = async(data) => {
    console.log(data)
    const { firstName, lastName, email, mobileNumber, message } = data;
    
    const emailData = {
      to_email: 'bhoomi6296@gmail.com',
      subject: 'Innovative Flutter : Inquiry',
      body: JSON.stringify(data),
      from_email: 'savani.uts@hotmail.com'
  };
  try {
    const response = await sendEmail(emailData);
    console.log(response)
    reset()
  } catch (error) {
    console.log((error))
  } 
  };

  return (
    <>
      <div className='contactUs-page'>
        <div className='bg'>
          <div className='content-div text-center'>
            <p className='main-heading mb-0'>Contact Us</p>
            <p className='main-description mb-0'>
              Let’s Talk About Your Next Project
            </p>
          </div>
        </div>
      </div>
     <div className='contactUs-div'>
     <div className='spacing text-center'>
        <p className='m-0 heading'>
          Get In Touch
        </p>
        <p className='main-description mb-0'>
          We will be in touch shortly
        </p>
        <p className='main-content mb-0 grey'>
          Anticipate our prompt communication.
        </p>
        <div className='row row-cols-1 row-cols-md-3 text-start pt-3 px-5'>
          <div className='col height p-2'>
              <div className='contact d-flex align-items-center p-3 py-4'>
              <FontAwesomeIcon className='icons' icon={faLocationDot} />
                <div className='m-0 ps-3 content'>
                  <p className='title m-0'>
                    ADDRESS
                  </p>
                  406, Elita Squre, VIP circle,
                  Uttran, Surat- 394105
                </div>
              </div>
          </div>
          <div className='col height p-2'>
              <div className='contact d-flex align-items-center p-3 py-4'>
              <FontAwesomeIcon className='icons' icon={faEnvelope} />
                <div className='m-0 ps-3 content'>
                  <p className='title m-0'>
                    EMAIL
                  </p>
                  info@innovativeflutter.com
                </div>
              </div>
          </div>
          <div className='col height p-2'>
              <div className='contact d-flex align-items-center p-3 py-4'>
              <FontAwesomeIcon className='icons' icon={faLocationDot} />
                <div className='m-0 ps-3 content'>
                  <p className='title m-0'>
                    CONTACT
                  </p>
                  +918000059470
                </div>
              </div>
          </div>
        </div>
      </div>
      <div id='contactSection' className=' text-center  px-0 px-lg-5'>
          <p className='m-0 heading'>
            Send us a message
          </p>
          <p className='main-description mb-0'>
            Have other questions?
          </p>
          <p className='main-content mb-0 grey'>
            Need more information? Feel free to inquire.
          </p>
          <form className='contact-form' onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group row row-cols-1 row-cols-lg-2">
            <div className="col mb-3 mb-lg-0">
              <input
                type="text"
                className={`form-control`}
                placeholder="First name"
                {...register('firstName', { required: 'First name is required' })}
              />
              {errors.firstName && <p className="text-warning text-start">{errors.firstName.message}</p>}
            </div>
            <div className="col">
            <input
              type="email"
              className={`form-control`}
              placeholder="Email"
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              })}
            />
            {errors.email && <p className="text-warning text-start">{errors.email.message}</p>}
             </div>
          </div>
          <div className="form-group">
            <input
              type="number"
              className={`form-control`}
              placeholder="Mobile Number"
              {...register('mobileNumber', { required: 'Mobile Number is required' })}
            />
            {errors.mobileNumber && <p className="text-warning text-start">{errors.mobileNumber.message}</p>}
          </div>
          <div className="form-group">
          <input
                type="text"
                className={`form-control`}
                placeholder="Subject"
                {...register('subject', { required: 'Subject is required' })}
              />
              {errors.subject && <p className="text-warning text-start">{errors.subject.message}</p>}
           
           </div>
          <div className="form-group">
            <textarea
              className={`form-control ${errors.message ? 'is-invalid' : ''}`}
              rows="3"
              placeholder="Message"
              {...register('message', { required: 'Message is required' })}
            ></textarea>
            {errors.message && <p className="text-warning text-start">{errors.message.message}</p>}
          </div>
          <button type="submit" className="btn rounded-pill text-center border-0 py-2 text-white">SEND MESSAGE</button>
        </form>
        </div>
     </div>
    </>
  )
}

export default ContactUs