import React from 'react'
import ChooseUsSection from './sections/ChooseUsSection'
import FormSection from './sections/FormSection'

function WhyUs() {
  return (
    <>
    <div className='whyUs-page'>
      <div className='bg'>
        <div className='content-div text-center'>
          <p className='main-heading mb-0'>Why Us</p>
          <p className='main-description mb-0'>
          Choose Us: Where Your Vision Becomes Reality
          </p>
        </div>
      </div>
    </div>
    <ChooseUsSection/>
    <FormSection/>
  </>
  )
}

export default WhyUs