import React, { useContext, useEffect, useState } from 'react';
import logo from "../../assests/media/images/logo.png";
import nav from '../../router/nav';
import { useNavigate, useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { Context } from '../../helper/ContextProvider';

function Navbar() {
  const {isCourse,setisCourse} =useContext(Context)
  const [activeLink, setActiveLink] = useState('');
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setActiveLink(location.pathname);
    handleResize()
  }, []);

  const handleResize = () => {
    const isMobileScreen = window.innerWidth;
    console.log(isMobileScreen)
    if(isMobileScreen<=520){
      setIsSmallScreen(true)
    }
    else{
      setIsSmallScreen(false)
    }
  };

  const handleSetActiveLink = (link) => {
    if(link){
      setActiveLink(link);
    navigate(link);
    }
    else{
    if(activeLink=='/'){
      setisCourse(true)
    }
    else{
      setActiveLink(link)
      setisCourse(true)
      navigate(link)
    }
    }
  };

  const NavigateToContactUs=()=>{
    navigate(`/contactus#contactSection`)
  }

  return (
    <div className='navbar-main'>
      <div className='px-2 px-md-3 py-2 py-lg-3'>
        <div className='px-0 px-lg-5'>
          <div className='px-0 px-lg-5'>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex align-items-center'>
                <img src={logo} href='/' alt='innovative-flutter' />
                <div className='title px-2'>Innovative Flutter</div>
              </div>
              {isSmallScreen ? (
                <div className='dropdown-div ms-auto'>
                  <Dropdown>
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                  <i className='fa fa-bars blue ps-0'></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ background: '#1D212D',inset: '5px auto auto 0px' }}>
                    {
                      nav?.map((x,i)=>{
                        return(
                          <Dropdown.Item
                           key={x.id} 
                           className={`${activeLink == x.path ? 'active' : ''}`}
                           onClick={() => handleSetActiveLink(x.path)}
                           >{x.name}</Dropdown.Item>
                        )
                      })
                    }
                  </Dropdown.Menu>
                </Dropdown>    
                </div>
              ) : (
                <div className='horizontal-div'>
                   <ul className='w-100 d-flex justify-content-around align-items-center p-0'>
                {
                  nav?.map((x, i) => {
                   return(
                    <li className='px-1' style={{cursor:'pointer'}} key={x.id}>
                    <p  className={`${activeLink == x.path ? 'active' : ''} mb-0 `}
                      onClick={() => handleSetActiveLink(x.path)}
                    >{x.name}</p>
                  </li>
                   )
                  })
                }
              </ul>
                </div>
              )}
              <div>
                <button className='rounded-pill nav-btn' onClick={NavigateToContactUs}>Get a free quote</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
