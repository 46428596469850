import React from 'react'
import FormSection from './sections/FormSection'
import courses from '../router/coursesArray'

function CourseThree() {
  return (
    <div className='courseThree'>
      <div className='courseThree-page'>
        <div className='bg'>
          <div className='content-div text-center'>
            <p className='main-heading mb-0'>Our Courses</p>
            <p className='main-description mb-0'>
              Home / Our Courses / Flutter & Dart
            </p>
          </div>
        </div>
      </div>
      <div className='spacing'>
        {
          courses.courseThree.map((x, i) => {
            return (
              <>
                <div className='text-center courses-div'>
                  <p className='main-description  mb-2'>
                    {x.firstTitle}
                  </p>
                  <p className='main-content text-white mb-3 grey'>
                    {x.firstContent}
                  </p>
                  <div className='d-flex justify-content-center mb-5'>
                    <button className=' button me-2 main-content '>{x.firstButton1}</button>
                    <button className='button ms-2 main-content'>{x.firstButton2}</button>
                  </div>
                  <p className='main-description  mb-2'>
                    {x.secondTitle}
                  </p>
                  <ul className='row row-cols-2 justify-content-center mb-5'>
                    {
                      x.secondcontent.map((y,j)=>{
                        return(
                          <li>{y.including}</li>
                        )
                      })
                    }
                     </ul>
                     <p className='main-description  mb-2'>
                    {x.thirdTitle}
                  </p>
                  <div className='d-flex justify-content-center mb-5'>
                    <button className=' button me-2 main-content '>{x.thirdButton1}</button>
                    <button className='button ms-2 main-content'>{x.thirdButton2}</button>
                  </div>
                  <p className='main-description  mb-2'>
                  Fundamentals Flutter
                  </p>
                  <div className='row row-cols-2 bullets-sec'>
                    <div className='col pe-2'>
                      <div className='bullets'>
                        <p className='m-0 blue'>Introduction to Flutter and Dart</p>
                        <div className='d-flex'>
                            <p>1. </p>
                            <div>
                              <p className='ps-2'>Overview of Flutter and Dart</p>
                              <p>- History and Purpose of Flutter and Dart</p>
                              <p>- Flutter’s Architecture and Ecosystem</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                            <p>2. </p>
                            <div>
                              <p className='ps-2'>Setting up the Development Environment</p>
                              <p>- Installing Flutter SDK and Dart</p>
                              <p>- Introduction to IDEs for Flutter :e.g., Android Studio, VS Code</p>
                          </div>
                        </div>
                        </div>
                        <div className='bullets'>
                        <p className='m-0 blue'>Basics of Dart Programming</p>
                        <div className='d-flex'>
                            <p>3. </p>
                            <div>
                              <p className='ps-2'>Dart Syntax and Basic Concepts</p>
                              <p>- Dart Program Structure</p>
                              <p>- Variables, Data Types, and Operators</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                          <p>4. </p>
                          <div>
                            <p className='ps-2'>Control Flow in Dart</p>
                            <p>- Conditional Statements and Loops</p>
                            <p>- Functions and Exception Handling</p></div>
                        </div>
                        </div>
                        <div className='bullets'>
                        <p className='m-0 blue'>Flutter Basics</p>
                        <div className='d-flex'>
                            <p>5. </p>
                            <div>
                              <p className='ps-2'>Understanding Widgets and Material Design</p>
                              <p>- Introduction to Widgets</p>
                              <p>- mplementing Material Design</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                          <p>6. </p>
                          <div>
                            <p className='ps-2'>State Management in Flutter</p>
                            <p>- Stateless and Stateful Widgets</p>
                            <p>-  Understanding the Widget Lifecycle</p>
                            </div>
                        </div>
                        </div>
                        <div className='bullets'>
                        <p className='m-0 blue'>Building Layouts in Flutter</p>
                        <div className='d-flex'>
                            <p>7. </p>
                            <div>
                              <p className='ps-2'>Layout Widgets</p>
                              <p>- Rows, Columns, and Containers</p>
                              <p>- Using Scaffold and AppBar</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                          <p>8. </p>
                          <div>
                            <p className='ps-2'>Styling and Theming</p>
                            <p>- Text Styling and Fonts</p>
                            <p>- Colors and Themes</p></div>
                        </div>
                        </div>
                        <div className='bullets'>
                        <p className='m-0 blue'>Interactive Apps and Navigation</p>
                        <div className='d-flex'>
                            <p>9. </p>
                            <div>
                              <p className='ps-2'>User Input and Forms</p>
                              <p>- Text Fields and Form Validation</p>
                              <p>- Buttons and GestureDetector</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                          <p>10. </p>
                          <div>
                            <p className='ps-2'>Navigation and Routing</p>
                            <p>- Navigating Between Screens</p>
                            <p>- Passing Data Between Screens</p></div>
                        </div>
                        </div>
                        </div>
                        <div className='col pe-2'>
                        <div className='bullets'>
                        <p className='m-0 blue'>Advanced Flutter Concepts</p>
                        <div className='d-flex'>
                            <p>11. </p>
                            <div>
                              <p className='ps-2'>State Management Solutions</p>
                              <p>- Provider, Riverpod, Bloc</p>
                              <p>- Best Practices for State Management</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                            <p>12. </p>
                            <div>
                              <p className='ps-2'>Animations and Motion</p>
                              <p>- Basic Animations</p>
                              <p>- Custom Animation Controllers</p>
                          </div>
                        </div>
                        </div>
                        <div className='bullets'>
                        <p className='m-0 blue'>Working with Data and Backend</p>
                        <div className='d-flex'>
                            <p>13. </p>
                            <div>
                              <p className='ps-2'>Networking and APIs</p>
                              <p>- Fetching Data from the Internet</p>
                              <p>- JSON Serialization and Deserialization</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                            <p>14. </p>
                            <div>
                              <p className='ps-2'>Local Data Storage</p>
                              <p>- SQLite and Shared Preferences</p>
                              <p>- File Storage and Management</p>
                          </div>
                        </div>
                        </div>
                        <div className='bullets'>
                        <p className='m-0 blue'>Advanced Dart Concepts</p>
                        <div className='d-flex'>
                            <p>15. </p>
                            <div>
                              <p className='ps-2'>Asynchronous Programming</p>
                              <p>- Futures, Async, and Await</p>
                              <p>- Streams and StreamBuilders</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                            <p>16. </p>
                            <div>
                              <p className='ps-2'>Generics and Collections</p>
                              <p>- Understanding Dart Collections</p>
                              <p>- Implementing Generics</p>
                          </div>
                        </div>
                        </div>
                        <div className='bullets'>
                        <p className='m-0 blue'>Real-World Application Development</p>
                        <div className='d-flex'>
                            <p>17. </p>
                            <div>
                              <p className='ps-2'>Building a Complete Flutter  Application</p>
                              <p>- Planning and Structuring an App</p>
                              <p>- Implementing Features Step-by-Step</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                            <p>18. </p>
                            <div>
                              <p className='ps-2'>Testing and Debugging Flutter Apps</p>
                              <p>- Unit and Widget Tests</p>
                              <p>- Debugging Techniques</p>
                          </div>
                        </div>
                        </div>
                        <div className='bullets'>
                        <p className='m-0 blue'>Deployment and Beyond</p>
                        <div className='d-flex'>
                            <p>19. </p>
                            <div>
                              <p className='ps-2'>Deploying Flutter Apps</p>
                              <p>- Building for iOS and Android</p>
                              <p>- Publishing to App Stores</p>
                          </div>
                        </div>
                        <div className='d-flex'>
                            <p>20. </p>
                            <div>
                              <p className='ps-2'>Advanced Topics and Further Learning</p>
                              <p>- Exploring Flutter for Web and Desktop</p>
                              <p>- Resources for Continuing Education</p>
                          </div>
                        </div>
                        </div>    
                    </div>
                  </div>
                </div>
              </>
            )
          })
        }
      </div>
      <FormSection />
    </div>
  )
}

export default CourseThree